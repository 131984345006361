import React, {useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router";
import axios from "../api";
import useAxios from "axios-hooks";
import { useSnackbar } from "notistack";
import { usePermission, Permission } from "../component/Base/Permission";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://novemdata.io/">
      NOVEMDATA.IO
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();

  //const [login, setLogin] = React.useState("")
  //const [password, setPassword] = React.useState("")
  const [auth, setAuth] = React.useState({});
  const [loading, setLoading] =  React.useState(false);
  const [errorMessage, setError] = React.useState("");
  const [, setPermission] = usePermission();
  const [state, setState] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
  });

  const { enqueueSnackbar } = useSnackbar();

  //const [{ data, loading, error }, executePost] = useAxios(
    // {
    //   url: 'account/auth',
    //   method: 'POST', 
    //   withCredentials: true
    // },
    //  {manual: true});
  
  const history = useHistory();

  const { vertical, horizontal, open } = state;

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage.response ? errorMessage.response.data.message : errorMessage.message, { variant: "error" });
    }
  }, [enqueueSnackbar, errorMessage]);

  return loading ? (
    <Container component="main" maxWidth="xs">

    </Container>) :
  (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Authorization
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Login"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={e => setAuth({...auth, login: e.target.value})}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={e => setAuth({...auth, password: e.target.value})}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Запомнить меня"
          /> */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={e=>
              {
                axios.post('account/auth', auth)
                .then((response)=>{
                  console.log(response);
                  sessionStorage.setItem("novemdata.token", response.data.token);
                  axios.defaults.headers.Authorization = response.data.token;
                  if (response.data.permissions) {
                    setPermission(response.data.permissions);
                  }
                history.push("/");
                })
                .catch((error)=>{
                  setError(error)});
                // executePost({data: auth}).then((response)=>{
                //   sessionStorage.setItem("novemdata.token", response.data.token);
                //   axios.defaults.headers.Authorization = response.data.token;
                //   if (response.data.permissions) {
                //     setPermission(response.data.permissions);
                //   }
                // history.push("/");
                // });
                // }
              }}
          >
            Sign in
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Забыли пароль?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Хотите стать партнером?"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      {/* <Box mt={8}>
        <Copyright />
      </Box> */}
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={()=>setState({ ...state, open: false })}>
  <Alert onClose={()=>setState({ ...state, open: false })} severity="error">
    {errorMessage}
  </Alert>
</Snackbar>
    </Container>
  );
}